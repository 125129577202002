import React from 'react'
import {Container, Header} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import * as styles from './styles/pages.module.css'

const TermsPage = ({location}) => {
	return (
		<Layout location={location}>
			<Seo title={"Terms and Conditions"} meta={[{name: `robots`, content: `noindex`}]} />
			<Container className={styles.container}>
				<Header className={styles.superHeader}><h1>Terms and Conditions</h1></Header>
				<p className={styles.text}>Last Updated: September 12, 2020</p>
				<p className={styles.text}>
					Please read these Terms and Conditions carefully before using the
					https://negosyonow.com website. Your access to and use of the Service
					is conditioned on your acceptance of and compliance with these Terms.
					These Terms apply to all visitors, users and others who access or use the
					Service. By accessing or using the Service you agree to be bound by these
					Terms. If you disagree with any part of the terms then you may not access the Service.
				</p>
				<Header className={styles.header}>Purchases</Header>
				<p className={styles.text}>
					If you wish to purchase any product or service made available
					through the Service, you may be asked to supply certain
					information relevant to your Purchase including, without
					limitation, your name, email, contact number and address.
				</p>
				<Header className={styles.header}>Special Packaging</Header>
				<p className={styles.text}>
					Styrofoam cooler, boxes or other form of special containment may be necessary for transport and/or preserving the condition of the goods.
					You will be charged accordingly for such containers.
					The fees will depend on factors such as the mode of transport, type of container, total size and weight of the items.
					These charges will be presented to you for approval prior to processing the transaction.
					Please refer to the payments section for settlement details.
				</p>
				<Header className={styles.header}>Third-Party Services</Header>
				<p className={styles.text}>
					You may avail of Third-Party Services such as courier, shippers or forwarders as you see fit.
					These services will be treated as customer representatives, and transactions will be considered complete upon turn over to your provider of choice.
					NegosyoNow will not be liable for incidents that occur during transport.
					These include, but are not limited to, alterations, tampering, pilferage, damages and/or degradation of items under the care of these services.
					Unless stated otherwise, you will be solely responsible for booking, coordination and payment of these services.
					Furthermore, it is strongly advised that you provide details of your provider and their representatives when available so we can prepare accordingly.
				</p>
				<p className={styles.text}>
					Involvement of the NegosyoNow team in the faciliation of Third-Party Services does not extend the liability of NegosyoNow.
					For transactions such as bus shipping, our representatives may book and settle the amount on your behalf when necessary.
					These transactions are also considered complete upon turn over of goods to the shipper.
					NegosyoNow will no longer be involved in the events that occur during and after shipment to the destination.
				</p>
				<Header className={styles.header}>Payments</Header>
				<p className={styles.text}>
					Payment method depends on the type of transaction.
					Please refer to the list below for the standard mode of payments.
				</p>
				<ul className={styles.text}>
					<li className={styles.listItem}>
						<strong>Delivery transactions</strong> are to be settled upon delivery of goods on a cash-on-delivery basis.
						Our delivery team will not bring change and they will not release the items without the exact amount.
					</li>
					<li className={styles.listItem}>
						<strong>Pickup transactions</strong> are to be settled upon pickup of goods on a cash-on-pickup basis.
						You will not be allowed to claim items prior to payment, nor will your representatives or third-party services.
						Please prepare accordingly to facilitate smooth transactions.
					</li>
					<li className={styles.listItem}>
						<strong>Bus Shipping transactions</strong> are to be settled via bank deposit prior to processing.
						A representative will contact you for the total amount due and bank deposit details.
						Our team will wait for the proof of payment so it can be verified against bank records.
						The transaction will only proceed after clearance of the full amount.
					</li>
				</ul>
				<p className={styles.text}>
					Please communicate using official channels and via authorized representatives only.
					Payments made outside of the prescribed methods may not be honored.
				</p>
				<Header className={styles.header}>Returns</Header>
				<p className={styles.text}>
					Issues and concerns regarding goods received or picked up may be raised through our
					communication channels within 7 days of receipt.  The resolution will be decided on a
					case to case basis.
				</p>
				<Header className={styles.header}>Links To Other Web Sites or Services</Header>
				<p className={styles.text}>
					Our Service may contain links to third-party web sites or services. We have no control
					over, and assume no responsibility for, the content, privacy policies, or practices of
					any third party web sites or services. You further acknowledge and agree to be solely
					responsible or liable, directly or indirectly, for any damage or loss caused or alleged
					to be caused by or in connection with use of or reliance on any such content, goods or
					services available on or through any such web sites or services.
				</p>
				<Header className={styles.header}>Changes</Header>
				<p className={styles.text}>
					We reserve the right, at our sole discretion, to modify or replace these
					Terms at any time. If a revision is material we will try to provide prior
					notice to any new terms taking effect. What constitutes a material change
					will be determined at our sole discretion. Feel free to contact us if you
					have any questions about these Terms.
				</p>
			</Container>
		</Layout>
	)
}

export default TermsPage
